
@mixin float-classes{
    .center  { float: none; margin-left: auto; margin-right: auto; }

    @if variable-exists(align-class-names) {
        @for $i from 1 through length($align-class-names) {
            @media #{(nth($align-class-breakpoints, $i))} {
                .#{(nth($align-class-names, $i))}-left    { float: left; }
                .#{(nth($align-class-names, $i))}-right   { float: right; }
                .#{(nth($align-class-names, $i))}-center  { float: none; margin-left: auto; margin-right: auto; }
            }
        }
    }
}