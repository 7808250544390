
/**
 * Generovani mezer (margin a padding) pro ruzne smery (EMPTY, top, right, bottom, left)
 * a ruzne media queries
 *
 * Zakladni inicializace:
 * @include spaces-init();
 *
 * Dogenerovani potrebnych zbytku:
 * $spaces-count: 2 6;
 * $spaces-directions: left;
 * @include spaces-init();
 */

@mixin spaces($property: '', $direction: 'top', $media: '') {
    $spaces-count: 0 6 !default;

    @if ($media != '') {
        $media: $media + '-';
    }

    @if ($property == 'margin' or $property == 'padding') {
        $property: $property + '-';
    } @else {
        $property: '';
    }


    @for $i from lower-bound($spaces-count) through upper-bound($spaces-count) {
        .#{unquote($media + $property + $direction + '-' + $i)} {
            #{unquote($property + $direction)}: #{$i * $base-line-height-rem};
        }
    }
}

@mixin spaces-directions-media($media: '') {
    $spaces-directions: top, bottom, right, left !default;

    @for $i from 1 through length($spaces-directions) {
        @include spaces( 'margin', nth($spaces-directions, $i), $media );
        @include spaces( 'padding', nth($spaces-directions, $i), $media );
    }
}

@mixin spaces-init() {
    $spaces-media-queries-breakpoints: '', $medium-up, $large-up !default;
    $spaces-media-queries-names: '', medium, large !default;

    @for $i from 1 through length($spaces-media-queries-names) {
        $media-query: nth($spaces-media-queries-breakpoints, $i);

        @if ($media-query != '') {
            @media #{$media-query} {
                @include spaces-directions-media( nth($spaces-media-queries-names, $i) );
            }
        }
        @else {
            @include spaces-directions-media( nth($spaces-media-queries-names, $i) );
        }
    }
}

