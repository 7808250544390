

.event-tabs {

	position: relative;
	display: block;

	.tab-title {

		position: relative;
		display: block;
		float: none;
		width: calc(100% - 20px);
		margin-bottom: rem-calc(5) !important;


		&.active {

			&::after {

				content: "";
				position: absolute;
				top: 0;
				right: -20px;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 58px 0 58px 20px;
				border-color: transparent transparent transparent $light-gray;
			}
		}

		a {

			text-decoration: none;
		}
	}
}

.tabs-content {

	box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.1);
	background: white;

	.content {
		padding: 0;
	}
}

.event-detail {

	padding: $base-line-height-rem;

	h3 {

		font-weight: $font-weight-bold;
	}

	.info-box {

		padding-right: $base-line-height-rem;

		i {
			font-size: rem-calc(24);
			margin-right: rem-calc(5);
		}
	}
}

.event-date {

	font-family: $header-font-family;
	font-size: rem-calc(48);
	text-align: center;
	text-transform: uppercase;
	display: inline-block;
	vertical-align: middle;
	margin-right: $base-line-height-rem;
	line-height: 1;

	.month {

		font-family: $body-font-family;
		font-size: rem-calc(18);
		display: block;
	}
}

.event-name {

	display: inline-block;
	vertical-align: middle;

	.location {

		display: block;

		i {

			font-size: rem-calc(24);
		}
	}
}