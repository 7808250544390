
@import url('https://fonts.googleapis.com/css?family=Anton|Montserrat:300,400&subset=latin-ext');



h1,h2,h3,h4,h5,h6 {

	letter-spacing: -0.05rem;
}

h1, h2{
	text-transform: uppercase;
}

h1 {

	line-height: 1;
}

h2 {

	margin-bottom: $base-line-height-rem;
}

h3, h4, h5, h6 {

	font-family: $body-font-family;
	font-weight: $font-weight-bold;
	line-height: 1.25;
}


.perex {

	color: $secondary-color;
	font-size: rem-calc(20);
	line-height: 1.2;
}

p {
	max-width: 35rem;
}

.line-long {

	display: block;
	height: $base-line-height-rem * 3;
	max-width: rem-calc(2);
	background-color: $light-gray; 
	margin: $base-line-height-rem;
}

.line-short {

	@extend .line-long;
	height: $base-line-height-rem;
}