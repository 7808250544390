

.page-footer {

    padding: $base-line-height-rem 0 0;
    color: $light-gray;
}

.copy-row {

    padding: $base-line-height-rem * .5;
    font-size: rem-calc(14);
    color: $base;
}

.footer-logo {

	max-width: rem-calc(40);
	display: inline-block;
	vertical-align: middle;
	margin: 0 $base-line-height-rem;
}


