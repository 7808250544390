


.social-circle {
	
	width: rem-calc(24);
	height: rem-calc(24);
	display: inline-block;
	vertical-align: middle;
	background-color: $base;
	border-radius: $global-rounded;
	margin: 0 $base-line-height-rem * .25;
	position: relative;
	transform: scale(1);
	transition: all .3s ease-in-out;

	i {

		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		color: $gray;
		font-size: rem-calc(16);	
	}

	&:hover {

		transform: scale(1.1);
	}
}