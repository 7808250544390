

.bg-primary {
    background-color: $primary-color;
}

.bg-secondary {
    background-color: $secondary-color;
}

.bg-light {
    background-color: $white-smoke;
}

.bg-dark {
    background-color: $oil;
}

.bg-white {
    background-color: $white;
}

.bg-base {
	background-color: $base; 
}

.bg-gray {
	background-color: $gray;
}

.bg-light-gray {
	background-color: $light-gray;
}

.bg-dark-gray {
	background-color: $dark-gray;
}
