

.section-team {

	background: url(../images/theme/team.png) no-repeat;
	background-position: 0 50%;
	background-size: auto 80%;
}

@media #{$medium-up} {

	.section-team {

		background-size: auto 100%;
		opacity: 1;
	}
}