

.navigation {

	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: $secondary-color;
	transition: all .3s ease-in-out;
	z-index: 100;
	opacity: 0;
	visibility: hidden;
	overflow: hidden;

	ul {
		display: block;
		width: 100%;
		text-align: center;
		list-style: none;
		position: absolute;
		top: 50%;
		transform: translateY(100%);
		transition: all .3s ease-in-out;
		margin: 0;

		a {
	
			color: white;
			text-decoration: none;
			display: block;
			padding: $base-line-height-rem;
			font-family: $header-font-family;
			font-size: rem-calc(30);
			text-transform: uppercase;
			&:hover {

				background-color: rgba(255,255,255,.1);
			}
		}
	}

	&.opened {

		visibility: visible;
		opacity: 1;

		ul {

			transform: translateY(-50%);
		}
	}

}

.close-menu-button {

	position: absolute;
	top: rem-calc(16);
	right: rem-calc(24);
	padding: rem-calc(10);
	cursor: pointer;
	color: white;

	i {

		font-size: rem-calc(30);
	}
}


.menu-button {

	display: block;
	position: fixed;
	top: $base-line-height-rem;
	right: $base-line-height-rem;
	z-index: 90;
	color: $secondary-color;
	padding: $base-line-height-rem * .5;
	font-size: rem-calc(24);
	cursor: pointer;
}

@media #{$large-up} {

	.navigation {

		height: auto;
		background-color: transparent;
		visibility: visible;
		opacity: 1;
		transform: translateY(-100%);
		padding: 0 $base-line-height-rem;

		&.nav-visible {
	
			transform: translateY(0);
			background: rgba(255,255,255,.9);
		}

		ul {

			transform: translateY(0);
			position: relative;
			text-align: right;

			li {
				display: inline-block;

				a {

					display: inline-block;
					color: $secondary-color;
					padding: $base-line-height-rem * .5;
					font-family: $body-font-family;
					font-size: rem-calc(18);
					text-transform: none;
				}
			}
		}
	}
}