

.header {
	position: relative;
}

.header-bg {

	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	z-index: -1;
	max-width: 80%;
}

.header-logo {

	max-width: rem-calc(80);
	opacity: .5;
	margin-bottom: $base-line-height-rem * 3;
}