

.page-intro {
    @extend %page-section;

    .page-section-content {
        margin: ($base-line-height-rem * 3) 0;
    }
}

@media #{$medium-up} {

    .page-intro {

        .page-section-content {
            margin: ($base-line-height-rem * 4) 0;
        }
    }
}


@media #{$large-up} {

    .page-intro {

        .page-section-content {
            margin: ($base-line-height-rem * 6) 0;
        }
    }
}
