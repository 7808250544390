
/**
 * Pri pouzivani fontu z Typekitu je dulezite vyuzit fallback pro IE8
 * 
 * Pro optimalini zobrazeni fontu na IE8 jsou pres webfontloader inicializovany
 * jednotlive rezy pisem jako samostane fonty. Tato mixina zajistuje preklad.
 *
 * Documentation:
 * http://help.typekit.com/customer/portal/articles/6855-using-multiple-weights-and-styles
 *
 * Parametry:
 * @param  $family         Nazev fontu (muze byt i vice fontu, ale typekit musi byt jako prvni)
 * @param  $weight         Tloustka pisma (100..900)
 * @param  $style          Style pisma (normal|italic)
 * @param  $propertysufix  Pri potrebe nastavit napriklad !important
 *
 * Priklad pouziti:
 * @include font();
 * @include font($font-family-monospace);
 * @include font($font-family-monospace, 700, italic, !important);
 */

@mixin font($family: $body-font-family, $weight: $body-font-weight, $style: $body-font-style, $propertysufix: '') {
    // Vyhledani prvniho fotnu ze zadanch
    $family-base-index: str-index($family + '', ','); 

    @if ($family-base-index != null) {
        $family-base: str-slice($family + '', 0, ($family-base-index - 1));
    } @else {
        $family-base: $family;
    }

    $family-base: unquote($family-base);

    // Vygenerovani potrebneho sufixu
    $family-sufix-style: str-slice($style, 1, 1);
    $family-sufix-weight: ($weight / 100);
    $family-sufix: $family-sufix-style + $family-sufix-weight;

    @if ($propertysufix != '') {
        $propertysufix: ' ' + $propertysufix;
    }

    // Kompletace
    font-family: quote(#{$family-base} + '-' + #{$family-sufix}), #{$family}#{$propertysufix};
    font-weight: unquote( $weight + $propertysufix );

    @if $style != normal {
        font-style: unquote( $style + $propertysufix );
    }
}