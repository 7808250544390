
body {
	z-index: 0;
	transition: all .3s ease-in-out;

	&.menu-open {
		transform: translateX(-50%);

		.nav-toggle {
			color: white;
			transform: translateX(40px);
		}
	}
}

.menu {
	position: fixed;
	display: block;
	top: 0;
	right: 0;
	z-index: 999;
	width: 50vw;
	height: 100vh;
	background: $black;
	color: white;
	transform: translateX(100%);
	transition: all ease-in-out .3s;
	padding: $base-line-height-rem * 2;
	list-style: none;


	.nav-toggle {
		color: $black;
		transform: translateX(-40px);
		transition: all .3s ease-in-out;
	}
}