
/**
 * Generovani velikosti textu dle nastaveni foundationu
 *
 * Zakladni inicializace:
 * @include font-size-init();
 */

@mixin font-size($media: '') {
    $text-sizes: $h1-font-size, $h2-font-size, $h3-font-size, $h4-font-size, $h5-font-size, $h6-font-size !default;
    $text-sizes-reduction: $h1-font-reduction, $h2-font-reduction, $h3-font-reduction, $h4-font-reduction, $h5-font-reduction, $h6-font-reduction !default;

    @if ($media != '') {
        $media: $media + '-';
    }

    @for $i from 1 through 6 {
        $font-size: nth($text-sizes, $i);

        @if ($media == '') {
            $font-size: $font-size - nth($text-sizes-reduction, $i);
        }

        .#{$media}text-h#{$i} {
            font-size: $font-size;
        }
    }
}

@mixin font-size-init() {
    $text-media-queries-names: '', medium, large !default;
    $text-media-queries-breakpoints: '', $medium-up, $large-up !default;

    @for $i from 1 through length($text-media-queries-names) {
        $media-query: nth($text-media-queries-breakpoints, $i);

        @if ($media-query != '') {
            @media #{$media-query} {
                @include font-size( nth($text-media-queries-names, $i) );
            }
        }
        @else {
            @include font-size( nth($text-media-queries-names, $i) );
        }
    }
}

