

.section-about-us {

	background: url(../images/theme/about.png) no-repeat;
	background-position: top left;
	position: relative;
}

.about-us-product-img {

	position: absolute;
	right: rem-calc(-40);
	top: rem-calc(-120);
	height: 150%;
	max-height: rem-calc(530);
	max-width: initial;

}