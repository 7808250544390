

.select-box{

    overflow: hidden;
    display: inline-block;
    border-radius: $global-radius;
    border: 1px solid $light-gray;
    position: relative;
    cursor: pointer;
    padding-right: rem-calc(20);
    font-size: rem-calc(30);
    font-weight: $font-weight-bold;
    color: $secondary-color;

    &::after {

    	content: "";
    	position: absolute;
    	transform: translateY(-50%);
    	top: 50%;
    	right: rem-calc(10);
    	width: 10px;
    	height: 10px;
    	width: 0;
		height: 0;
		border-style: solid;
		border-width: 10px 7.5px 0 7.5px;
		border-color: $secondary-color transparent transparent transparent;
    }

	select {

	    appearance:none;
	    width: calc(100% + 55px);
	    background:none;
	    background:transparent;
	    border:none;
	    outline:none;
	    cursor:pointer;
	    padding: rem-calc(12) rem-calc(16);
	    font-size: $rem-base;
	}
}