

.text-h1 {

	@extend h1;
	font-size: $h1-font-size !important;	
}

.text-h2 {

	@extend h2;
	font-size: $h2-font-size !important;
}

.text-h3 {

	@extend h3;
	font-size: $h3-font-size !important;	
}

.text-h4 {

	@extend h4;
	font-size: $h4-font-size !important;	
}

.text-h5 {

	@extend h5;
	font-size: $h5-font-size !important;	
}

.text-h6 {

	@extend h6;
	font-size: $h6-font-size !important;	
}

@media #{$small-up} {

	.text-h1 {

		font-size: $h1-font-size - $h1-font-reduction;
	}

	.text-h2 {

		font-size: $h2-font-size - $h2-font-reduction;
	}

	.text-h3 {

		font-size: $h3-font-size - $h3-font-reduction;
	}

	.text-h4 {

		font-size: $h4-font-size - $h4-font-reduction;
	}

	.text-h5 {

		font-size: $h5-font-size - $h5-font-reduction;
	}

	.text-h6 {

		font-size: $h6-font-size - $h6-font-reduction;
	}

}