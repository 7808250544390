
.product-bg {

	position: absolute;
	z-index: -1;
	left: rem-calc(-80);
	right: auto;
	top: rem-calc(150);
	transform: translateY(0);
	opacity: .5;

	@media #{$small-only} {

		display: none;
	}
}

.product-bg2 {

	@extend .product-bg;
	width: initial;
	left: auto;
	right: 0;
	top: rem-calc(120);
	max-width: 70vw;
	opacity: 1;

	@media #{$medium-only} {
		opacity: .5;
	}
}


.product-menu {

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	border-top: 1px solid $light-gray;
	border-bottom: 1px solid $light-gray;
	padding: $base-line-height-rem * .5 0;
}

.product-price {

	font-size: rem-calc(30);
	text-align: left;
	font-weight: $font-weight-bold;

	span {
		display: block;
		font-size: rem-calc(16);
		font-weight: $font-weight-normal;
	}
}

.amount-button {

	display: inline-block;
	vertical-align: middle;
	background: $light-gray;
	color: $primary-color;
	width: rem-calc(25);
	height: rem-calc(25);
	border-radius: $global-rounded;
	font-size: rem-calc(12);
	line-height: rem-calc(25);
	text-align: center;
	margin: 0 rem-calc(5);
	cursor: pointer;
	transition: all ease-in-out .3s;

	&:hover {

		background: darken($light-gray, 7%);
	}
}

@media #{$medium-up} {

	.product-menu {

		flex-direction: row;
	}
}