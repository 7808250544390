

.page-topbar {
    @extend %page-section;
}

.page-topbar-content {
    margin: 0;
    padding: 0 ($base-line-height-rem / 2);
    @include clearfix();
}