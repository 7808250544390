

.page-section {
	position: relative;
	z-index: 1;
    @extend %page-section;
}

.page-section-content {
    padding: 0;
    margin: ($base-line-height-rem * 2) 0;
}


@media #{$medium-up} {

    .page-section-content {
        margin: ($base-line-height-rem * 3) 0;
    }
}


@media #{$large-up} {

    .page-section-content {
        margin: ($base-line-height-rem * 4) 0;
    }
}
